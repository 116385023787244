import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import ServiceDetails from "@/components/service-details";
import CallToActionOne from "@/components/call-to-action-one";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import HeaderOne from "@/components/header-one";
import SEO from "@/components/seo";

const WebsiteGraphics = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <SEO
          title="Solxk - Grafikdesign"
          description="Entdecken Sie die außergewöhnlichen Website -Grafikdienste von Solxk.Verbessern Sie Ihre digitale Präsenz mit unseren Experten -Design -Lösungen, die auf Entwickler und Unternehmen zugeschnitten sind."
        />
        <Layout PageTitle="WebsiteGraphics">
          <HeaderOne />
          <PageBanner title="Grafikdesign" name="Service" />
          <ServiceDetails />
          <CallToActionOne extraClassName="ready" />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default WebsiteGraphics;
